import React from 'react'
import { intl, t } from '../../../i18n'
import styles from './index.module.scss'

interface PriceProps {
  price: number
  isSelected: boolean
}
const AbsolutPrice: React.FC<PriceProps> = ({ price, isSelected }) => {
  const isActuallyFree = Math.round(price * 100) === 0

  return (
    <div className={styles.discounted_service_price_container}>
      <span
        className={isSelected ? styles.item_price_checked : styles.item_price}>
        {isActuallyFree ? (
          t('tu_solicitud.gratis')
        ) : (
          <React.Fragment>
            {intl.formatNumber(price, {
              style: 'currency',
              currency: 'EUR',
            })}
          </React.Fragment>
        )}
      </span>
    </div>
  )
}

export default AbsolutPrice
