import React from 'react'
import styles from './promotionBanner.module.scss'

export interface Promocion {
  texto_promociones: string
  color_background: string
  color_texto: string
  visible_cesta: boolean
}

interface PromotionBannerProps {
  promocion: Promocion
}

export const PromotionBanner = ({ promocion }: PromotionBannerProps) => (
  <div
    className={styles.promobanner}
    style={{
      backgroundColor: promocion.color_background,
      color: promocion.color_texto,
    }}>
    {promocion.texto_promociones}
  </div>
)
