import React, { useContext } from 'react'
import AppContext from '../../../context/context'
import {
  canCestaMontaje,
  hasMontajeDiscount,
} from '../../../context/selectors/cestaSelectors'
import * as CestaActions from '../../../context/actions/cestaActions'
import CestaRadioButtonItem from '../cestaItem/CestaRadioButtonItem'
import { MONTAJE_TALLER_ID } from '../../../context/sagas/cestaSagas'

const MotoOpciones: React.FC = () => {
  const { cestaState, dispatch } = useContext(AppContext)
  const canMontaje = canCestaMontaje(cestaState.products)

  const handleRadioChange = (selectedIdNavision: string) => {
    dispatch(CestaActions.switchService(selectedIdNavision))
  }

  return (
    <React.Fragment>
      {cestaState.services
        .sort((a, b) => (a.orden >= b.orden ? 1 : -1))
        .map((service) => (
          <CestaRadioButtonItem
            item={service}
            handleChange={handleRadioChange}
            key={`service_${service.id_navision}`}
            disabled={service.id_navision === MONTAJE_TALLER_ID && !canMontaje}
            products={cestaState.products.filter(
              (product) => product.tipo_producto === 'neumatico'
            )}
            hasServiceDiscount={
              service.id_navision === MONTAJE_TALLER_ID &&
              hasMontajeDiscount(cestaState.products)
            }
          />
        ))}
    </React.Fragment>
  )
}

export default MotoOpciones
