import React from 'react'
import { PromotionBanner } from '../../promotionBanner/PromotionBanner'
import { t, textWithLocale } from '../../../i18n'
import { Product, Service } from '../../../context/reducers/cestaReducer'
import Size from '../../../utils/media-queries-setup'
import Recomendado from '../../Recomendado/Recomendado'
import styles from './index.module.scss'
import Price from './Price'
import RadioButtonYellow from '../../radioButtonYellow/RadioButtonYellow'
import {
  MONTAJE_TALLER_ID,
  RECOGIDA_TALLER_BATERIA_ID,
  RECOGIDA_TALLER_ID,
} from '../../../context/sagas/cestaSagas'
import { CategoriaServicio } from '../../../types/CategoriaServicio'
import AbsolutPrice from './AbsolutPrice'
import { calculatePriceWithoutDiscount } from '../../../context/selectors/cestaSelectors'

interface CestaItemsProps {
  item: Service
  handleChange: (value: string | number | string[] | boolean) => void
  titleClass?: string
  highlighted?: boolean
  discountServicio?: number
  disabled?: boolean
  products?: Product[]
  hasServiceDiscount: boolean
}

export default function CestaRadioButtonItem({
  item,
  handleChange,
  highlighted,
  discountServicio,
  disabled,
  products,
  hasServiceDiscount,
}: CestaItemsProps) {
  const nombreProducto: string | null = item[textWithLocale('nombre_producto')]
    ? item[textWithLocale('nombre_producto')].trim()
    : null
  const descripcionProducto: string | null =
    item[textWithLocale('descripcion')] || null

  const renderServiceDescription = (service: Service) => {
    if ([MONTAJE_TALLER_ID].includes(service.id_navision)) {
      const montajesDesc = products.reduce((acc, product) => {
        const montaje = product.servicios.find(
          (servicio) => CategoriaServicio.Montaje === servicio.categoriaServicio
        )
        if (montaje) {
          acc.push({
            productDesc: product[textWithLocale('descripcion')],
            montajeDesc: montaje[textWithLocale('descripcion')],
          })
        }
        return acc
      }, [])
      return (
        <React.Fragment>
          {descripcionProducto} <br />
          {montajesDesc.map((i, key) => (
            <p key={key}>{`${i.productDesc} - ${i.montajeDesc}`}</p>
          ))}
        </React.Fragment>
      )
    }
    if (
      [RECOGIDA_TALLER_ID, RECOGIDA_TALLER_BATERIA_ID].includes(
        service.id_navision
      )
    ) {
      return (
        <React.Fragment>
          {descripcionProducto}
          <p>{t('selector-moto.service_recogida_claim')}</p>
        </React.Fragment>
      )
    }
    return descripcionProducto
  }

  const priceWithoutDiscount = hasServiceDiscount
    ? calculatePriceWithoutDiscount(item)
    : 0

  return (
    <React.Fragment>
      <div
        className={`${styles.selected_item_container} ${
          highlighted ? styles.discount_backgrounded : ''
        }`}>
        <div className={styles.entregaWrapper}>
          <div>
            <RadioButtonYellow
              onChange={handleChange}
              label1={<div className={styles.label1}>{nombreProducto}</div>}
              label2={
                <div className={styles.label2}>
                  {renderServiceDescription(item)}
                </div>
              }
              checked={item.selected}
              name={item.id_navision}
              value={item.id_navision}
              disabled={disabled}
            />
          </div>
        </div>
        <Size.Matcher
          mobile={null}
          desktop={
            'recomendado' in item && item.recomendado === 1 ? (
              <Recomendado />
            ) : null
          }
        />
        <Size.Matcher
          mobile={null}
          desktop={
            item.banner && item.banner.visible_cesta ? (
              <div className={styles.promobanner_container}>
                <PromotionBanner promocion={item.banner} />
              </div>
            ) : null
          }
        />
        <div className={styles.selected_item_right_section_without_delete}>
          <div className={styles.dropdown_container}>{null}</div>
          <div
            className={`${styles.price_container} ${
              disabled && styles.proximamente
            }`}>
            {!disabled ? (
              hasServiceDiscount ? (
                <AbsolutPrice
                  price={priceWithoutDiscount}
                  isSelected={'selected' in item && item.selected === true}
                />
              ) : (
                <Price
                  item={item}
                  discountServicio={discountServicio}
                  withCheckBox={true}
                />
              )
            ) : (
              t('steps_cesta.Proximamente')
            )}
          </div>
        </div>
      </div>
      {hasServiceDiscount && (
        <div
          className={`${styles.selected_item_container} ${styles.discount_backgrounded} ${styles.discount_montaje_container}`}>
          <div className={styles.text_padding}>
            <div className={styles.service_title}>{t('descuento')}</div>
            <span className={styles.service_description}>
              {t('promocion_online')}
            </span>
          </div>
          <div className={styles.selected_item_right_section_without_delete}>
            <div className={styles.dropdown_container}>{null}</div>
            <div
              className={`${styles.price_container} ${
                disabled && styles.proximamente
              }`}>
              <AbsolutPrice
                price={-1 * Number(item.promocion.importePromocion)}
                isSelected={'selected' in item && item.selected === true}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
