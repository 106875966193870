import { connect } from 'react-redux'
import {
  selectEstacion,
  selectMarca,
  selectMedida,
  selectRunflat,
} from '../../../context/actions/selectorActions'
import { IAppState } from '../../../context/context'
import { SelectorState } from '../../../context/reducers/selectorReducer'
import {
  selectSelector,
  selectSkipInfo,
} from '../../../context/selectors/selectorSelectors'
import { SelectorProps } from '../SelectorSteps'

const validate = (data: SelectorState) => {
  if (data.ancho.value === null) {
    return false
  }
  if (data.serie.value === null) {
    return false
  }
  if (data.llanta.value === null) {
    return false
  }
  if (data.carga.value === null) {
    return false
  }
  if (data.velocidad.value === null) {
    return false
  }
  return true
}

type BaseProps = Pick<
  SelectorProps,
  'skipInfo' | 'selectorState' | 'dispatch' | 'showMarcas' | 'origin'
>
interface Props extends BaseProps {
  children: (props: SelectorProps) => JSX.Element
}
const SelectorContainer = ({
  skipInfo,
  selectorState,
  dispatch,
  children,
  showMarcas,
  origin,
}: Props) => {
  const handleChangeEstacion = (value) => {
    dispatch(selectEstacion(value))
  }

  const handleChangeRunflat = (event) => {
    dispatch(selectRunflat(event.target.checked))
  }

  const handleChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    if (name === 'marca') {
      dispatch(selectMarca(value))
    } else {
      dispatch(
        selectMedida({
          name,
          value,
        })
      )
    }
  }

  return children({
    skipInfo,
    selectorState,
    dispatch,
    handleChange,
    handleChangeRunflat,
    handleChangeEstacion,
    isValid: validate,
    showMarcas,
    origin,
  })
}

const ConnectedSelectorContainer = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
  selectorState: selectSelector(state),
}))(SelectorContainer)

export default ConnectedSelectorContainer
