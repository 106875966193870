import React, { ReactElement, useRef, useLayoutEffect } from 'react'
import ReactDOMServer from 'react-dom/server'

export interface StaticIframeProps
  extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  onFirstLoad?: (doc: HTMLDocument) => void
  children: ReactElement
}

const StaticIframe: React.FC<StaticIframeProps> = ({
  onFirstLoad,
  ...props
}) => {
  const iframeRef = useRef<HTMLIFrameElement>()

  useLayoutEffect(() => {
    const { current: iframe } = iframeRef
    const doc = iframe.contentDocument
    const body = ReactDOMServer.renderToStaticMarkup(props.children)

    doc.open()
    doc.write(body)
    doc.close()

    onFirstLoad && onFirstLoad(doc)
  }, [])
  console.log('Rendering iframe')

  return <iframe ref={iframeRef} {...props} />
}

export default StaticIframe
