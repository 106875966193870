import React, { useMemo, useCallback } from 'react'
import { t } from '../../../../../common/i18n'
import DropDown, { Props as DropDownProps } from '../../../dropDown/DropDown'
import { Selectable } from '../../../../context/reducers/selectorReducer'

type InheritedOptions = Omit<DropDownProps, 'value' | 'options'>
interface SelectorDropDownProps extends InheritedOptions {
  field: Selectable<any>
  sorted?: boolean
}
const SelectorDropDown = ({
  field,
  required,
  sorted = false,
  onChange,
  ...props
}: SelectorDropDownProps) => {
  const [_options, _value] = useMemo(() => {
    const opts = sorted
      ? [...field.options].sort((a, b) => a.value - b.value)
      : field.options

    // If not required, then the "all" option is always available
    if (!required) {
      return [
        [
          {
            label: t(field.loading ? 'selector.cargando' : 'selector.todos'),
            value: '',
          },
          ...opts,
        ],
        field.value === null ? '' : field.value,
      ]
    }

    // Otherwise we only add the default when no value is selected
    return field.value !== null
      ? [opts, field.value]
      : [
          [
            {
              label: t(
                field.loading ? 'selector.cargando' : 'selector.seleccionar'
              ),
              value: '',
            },
            ...opts,
          ],
          '',
        ]
  }, [field.options, field.value, required, field.loading, sorted])
  const _onChange = useCallback(v => onChange(v === '' ? null : v), [onChange])

  return (
    <DropDown
      value={_value}
      options={_options}
      onChange={_onChange}
      {...props}
    />
  )
}

export default SelectorDropDown
