const DNI_REGEX = /^(\d{8})([A-Z])$/
const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/
const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/
const NIF_REGEX = /^[0-9]{9}$/

const validDNI = (dni: string): boolean => {
  if (!DNI_REGEX.test(dni)) {
    return false
  }
  const dni_letters = 'TRWAGMYFPDXBNJZSQVHLCKE'
  const letter = dni_letters.charAt(parseInt(dni, 10) % 23)

  return letter === dni.charAt(8)
}

const validNIE = (nie: string): boolean => {
  if (!NIE_REGEX.test(nie)) {
    return false
  }
  // Change the initial letter for the corresponding number and validate as DNI
  const nie_prefix = nie.charAt(0)

  let prefix: number
  switch (nie_prefix) {
    case 'X':
      prefix = 0
      break
    case 'Y':
      prefix = 1
      break
    case 'Z':
      prefix = 2
      break
  }

  return validDNI(prefix + nie.substring(1))
}

const validCIF = (cif: string): boolean => {
  if (!CIF_REGEX.test(cif)) {
    return false
  }

  const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
  const digits = cif.substring(1, cif.length - 1)
  const letter = cif.substring(0, 1)
  const control = cif.substring(cif.length - 1)

  if (!letter.match(/[A-Z]/)) {
    return false
  }
  let digit: number
  let sum = 0
  for (let i = 0; i < digits.length; ++i) {
    digit = parseInt(digits[i])

    if (isNaN(digit)) {
      return false
    }

    if (i % 2 === 0) {
      digit *= 2
      if (digit > 9) {
        digit = Math.floor(digit / 10) + (digit % 10)
      }

      sum += digit
    } else {
      sum += digit
    }
  }

  sum %= 10
  if (sum !== 0) {
    digit = 10 - sum
  } else {
    digit = sum
  }

  if (letter.match(/[ABEH]/)) {
    return String(digit) === control
  }
  if (letter.match(/[NPQRSW]/)) {
    return letters[digit] === control
  }

  return String(digit) === control || letters[digit] === control
}

const validNIFpt = (nif: string): boolean => {
  if (nif.length === 9 && nif.match(NIF_REGEX)) {
    const added =
      parseInt(nif[7]) * 2 +
      parseInt(nif[6]) * 3 +
      parseInt(nif[5]) * 4 +
      parseInt(nif[4]) * 5 +
      parseInt(nif[3]) * 6 +
      parseInt(nif[2]) * 7 +
      parseInt(nif[1]) * 8 +
      parseInt(nif[0]) * 9
    const mod = added % 11
    const control = mod === 0 || mod === 1 ? 0 : 11 - mod

    if (parseInt(nif[8]) === control) {
      return true
    }
  }
  return false
}

enum nifTypeEnum {
  DNI = 'DNI',
  NIE = 'NIE',
  CIF = 'CIF',
  NIF = 'NIF',
}
type nifTypes = 'DNI' | 'NIE' | 'CIF' | 'NIF'
export const ValidateID = function (str: string, type: nifTypes) {
  // Ensure upcase and remove whitespace
  str = str.toUpperCase().replace(/\s/g, '')

  switch (type) {
    case nifTypeEnum.DNI:
      return validDNI(str)
    case nifTypeEnum.NIE:
      return validNIE(str)
    case nifTypeEnum.CIF:
      return validCIF(str)
    case nifTypeEnum.NIF:
      return validNIFpt(str)
  }
  return false
}
