import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../../../context/context'
import * as CestaActions from '../../../context/actions/cestaActions'
import {
  findApplyingServicePromotion,
  itIsDiscountedService,
} from '../../../context/selectors/cestaSelectors'
import CestaItem from '../cestaItem/CestaItem'

// We identify the CTA type for customizing the options
const TIPO_VEHICULO_FURGONETA = 1
const CocheOpciones: React.FC = () => {
  const { cestaState, dispatch } = useContext(AppContext)
  const [noSeguro, setNoSeguro] = useState(false)

  useEffect(() => {
    setNoSeguro(
      cestaState.products.some(
        (product) =>
          product.runflat ||
          product.id_site_tipo_vehiculo === TIPO_VEHICULO_FURGONETA
      )
    )
    if (noSeguro) {
      const service = cestaState.services.find(
        (item) => item.id_navision === 'SEGURO_NEUMATICO'
      )
      const isCurrentlySelected = 'selected' in service && service.selected
      if (isCurrentlySelected) {
        dispatch(
          CestaActions.checkService('SEGURO_NEUMATICO', isCurrentlySelected)
        )
      }
    }
  })

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: id } = event.target
    const service = cestaState.services.find((item) => item.id_navision === id)
    const isCurrentlySelected = !!('selected' in service && service.selected)
    dispatch(CestaActions.checkService(id, isCurrentlySelected))
  }

  return (
    <React.Fragment>
      {cestaState.services
        .sort((a, b) => (a.orden > b.orden ? 1 : -1))
        // Nos saltamos los productos gratuitos no incluídos
        // (test de seguridad)
        .filter((s) => s.importe !== 0 || s.selected)
        // También el seguro de neumatico si no hay que incluirlo
        .filter((s) => !(noSeguro && s.id_navision === 'SEGURO_NEUMATICO'))
        .map((item, index) => {
          const applyingPromotion = findApplyingServicePromotion(
            cestaState.products
          )
          let highlighted = false
          if (itIsDiscountedService(item, applyingPromotion)) {
            highlighted = true
          }
          return (
            <CestaItem
              handleCheckBoxChange={handleCheckBoxChange}
              withCheckBox={true}
              key={index}
              item={item}
              highlighted={highlighted}
              discountServicio={
                itIsDiscountedService(item, applyingPromotion)
                  ? applyingPromotion.importe_cupon
                  : 0
              }
            />
          )
        })}
    </React.Fragment>
  )
}
export default CocheOpciones
