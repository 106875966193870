import styles from './index.module.scss'
import { t } from '../../../i18n'
import { isAndorraPostalCode } from '../../../utils/getTarifa'
import React from 'react'
import CestaProductsList from '../cestaProductsList/CestaProductsList'
import { CestaState } from '../../../context/reducers/cestaReducer'
import { UserState } from '../../../context/reducers/userReducer'
import { FormattedMessage } from 'react-intl'

interface Props {
  cestaState: CestaState
  tienePortes: boolean
  userInfo: UserState
  goToFechaHora: () => void
}
export default function InfoModalTaller({
  cestaState,
  tienePortes,
  userInfo,
  goToFechaHora,
}: Props) {
  return (
    <React.Fragment>
      <p className={styles.infoModal}>
        {t('resultados_neumaticos.importe_modificado', {
          pais: isAndorraPostalCode(userInfo.postCode)
            ? t('paises.spain')
            : t('paises.andorra'),
        })}
        {tienePortes && (
          <React.Fragment>
            <br />
            <span>{t('resultados_neumaticos.portes')}</span>
          </React.Fragment>
        )}
      </p>
      <CestaProductsList cestaState={cestaState} putInfoTaller={false} />
      <p className={styles.iva_incl}>
        {isAndorraPostalCode(userInfo.postCode)
          ? t('tu_solicitud.iva_incluido')
          : t('tu_solicitud.igi_incluido')}
      </p>
      <button
        className={styles.button}
        disabled={false}
        onClick={goToFechaHora}>
        <FormattedMessage id="selector-desktop.continuar" />
      </button>
    </React.Fragment>
  )
}
