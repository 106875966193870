import React, { useCallback, useMemo, useState } from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { t } from '../../../i18n'
import { IUserData } from '../../../context/reducers/userReducer'
import { FullPaymentObject, PaymentObject } from '../../../types/Cesta'
import SpinnerWithText from '../../spinnerWithText/SpinnerWithText'
import StaticIframe, {
  StaticIframeProps,
} from '../../StaticIframe/StaticIframe'
import styles from './paymentForm.module.scss'
import { normalize } from '../../../utils/charactersEscaping'
import merchantResponseUrl from '../../../utils/merchantResponseUrl'
import { DatosFacturacionData } from '../../../helpers/schemas/FacturacionSchema'

const escape = (str: string) => normalize(str).replace(/[^a-z0-9\s]/gi, '')

const buildFieldsFromFacturacion = (datos: DatosFacturacionData) => {
  // These three fields only accept ^[a-zA-Z0-9\s]*$
  const billing1 = escape(`${datos.address}, ${datos.number}`)
  const billing2 = escape(`${datos.block}`)
  const city = escape(`${datos.city}`)
  return {
    HPP_BILLING_STREET1: billing1,
    HPP_BILLING_STREET2: billing2,
    HPP_BILLING_CITY: city,
    HPP_BILLING_POSTALCODE: `${datos.postCode}`,
  }
}
const buildFieldsFromUserData = (user: IUserData) => ({
  HPP_CUSTOMER_EMAIL: `${user.email}`,
  HPP_CUSTOMER_PHONENUMBER_MOBILE: `34|${
    user.phone.length > 9 ? user.phone.substring(3) : user.phone
  }`,
})

type PaymentFormProps = {
  paymentObject: PaymentObject
  datosFacturacion: DatosFacturacionData
  userData: IUserData
  onFirstLoad?: (doc: HTMLDocument) => void
} & Omit<StaticIframeProps, 'children'>

const summary = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

const BasePaymentForm: React.FC<
  {
    payment: FullPaymentObject
    onFirstLoad: (doc: HTMLDocument) => void
  } & InjectedIntlProps
> = ({ intl, payment, ...props }) => {
  console.log('Rendering', summary(JSON.stringify(payment)))
  if (process.env.PAYMENT_SYSTEM === 'new') {
    return <iframe src={payment.URL}></iframe>
  }
  return (
    <StaticIframe {...props}>
      <form
        id="preform"
        method="POST"
        action={process.env.GATSBY_ADDONPAYMENTS_URL}>
        <input type="hidden" name="TIMESTAMP" value={payment.TIMESTAMP} />
        <input type="hidden" name="MERCHANT_ID" value={payment.MERCHANT_ID} />
        <input type="hidden" name="ACCOUNT" value={payment.ACCOUNT} />
        <input type="hidden" name="ORDER_ID" value={payment.ORDER_ID} />
        <input type="hidden" name="AMOUNT" value={payment.AMOUNT} />
        <input type="hidden" name="CURRENCY" value={payment.CURRENCY} />
        <input type="hidden" name="SHA1HASH" value={payment.SHA1HASH} />
        <input
          type="hidden"
          name="AUTO_SETTLE_FLAG"
          value={payment.AUTO_SETTLE_FLAG}
        />
        <input type="hidden" name="COMMENT1" value={payment.COMMENT1} />
        <input type="hidden" name="COMMENT2" value={payment.COMMENT2} />
        {/* <input
          type="hidden"
          name="SHIPPING_CODE"
          value={payment.SHIPPING_CODE}
        />
        <input type="hidden" name="SHIPPING_CO" value={payment.SHIPPING_CO} />
        <input type="hidden" name="BILLING_CODE" value={payment.BILLING_CODE} />
        <input type="hidden" name="BILLING_CO" value={payment.BILLING_CO} /> */}
        <input
          type="hidden"
          name="HPP_BILLING_STREET1"
          value={payment.HPP_BILLING_STREET1}
        />
        <input
          type="hidden"
          name="HPP_BILLING_STREET2"
          value={payment.HPP_BILLING_STREET2}
        />
        <input
          type="hidden"
          name="HPP_BILLING_STREET3"
          value={payment.HPP_BILLING_STREET3 || ''}
        />
        <input
          type="hidden"
          name="HPP_BILLING_CITY"
          value={payment.HPP_BILLING_CITY}
        />
        <input
          type="hidden"
          name="HPP_BILLING_POSTALCODE"
          value={payment.HPP_BILLING_POSTALCODE}
        />
        {/* <input type="hidden" name="HPP_BILLING_STATE" value={'ES'} /> */}
        <input type="hidden" name="HPP_BILLING_COUNTRY" value={'724'} />
        <input
          type="hidden"
          name="HPP_CUSTOMER_EMAIL"
          value={payment.HPP_CUSTOMER_EMAIL}
        />
        <input
          type="hidden"
          name="HPP_CUSTOMER_PHONENUMBER_MOBILE"
          value={payment.HPP_CUSTOMER_PHONENUMBER_MOBILE}
        />
        <input type="hidden" name="CUST_NUM" value={payment.CUST_NUM} />
        <input type="hidden" name="VAR_REF" value={payment.VAR_REF} />
        <input type="hidden" name="PROD_ID" value={payment.PROD_ID} />
        <input type="hidden" name="HPP_LANG" value={payment.HPP_LANG} />
        {payment.HPP_CHALLENGE_REQUEST_INDICATOR ? (
          <input
            type="hidden"
            name="HPP_CHALLENGE_REQUEST_INDICATOR"
            value={payment.HPP_CHALLENGE_REQUEST_INDICATOR}
          />
        ) : null}
        <input type="hidden" name="HPP_VERSION" value="2" />
        <input
          type="hidden"
          name="HPP_POST_DIMENSIONS"
          value={document.location.href}
        />
        <input
          type="hidden"
          name="HPP_POST_RESPONSE"
          value={document.location.href}
        />
        <input
          type="hidden"
          name="MERCHANT_RESPONSE_URL"
          value={`${merchantResponseUrl()}/pedidos/notificacion-pago-pasarela`}
        />
        <input
          type="hidden"
          name="CARD_PAYMENT_BUTTON"
          value={
            'PAGAR ' +
            intl.formatNumber(payment.AMOUNT / 100.0, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) +
            '€'
          }
        />
        <input type="hidden" name="SUPPLEMENTARY_DATA" value="" />
        {/* <input type="submit" value={totalPayment.AMOUNT}/> */}
      </form>
    </StaticIframe>
  )
}

const MemoizedPaymentForm = React.memo(injectIntl(BasePaymentForm))

const PaymentForm: React.FC<PaymentFormProps> = ({
  paymentObject,
  userData,
  datosFacturacion,
  onFirstLoad,
  ...props
}) => {
  const payment = useMemo(
    () => ({
      ...paymentObject,
      ...buildFieldsFromUserData(userData),
      ...buildFieldsFromFacturacion(datosFacturacion),
    }),
    [paymentObject, userData, datosFacturacion]
  )
  const [loading, setLoading] = useState(true)
  // useEffect(() => {
  //   setLoading(true)
  // }, [payment])
  const onLoad = useCallback(() => {
    setLoading(false)
  }, [setLoading])

  const onFirstLoad2 = useCallback(
    (doc) => {
      onFirstLoad(doc)
      setLoading(true)
    },
    [setLoading]
  )

  return (
    <div className={styles.container}>
      <MemoizedPaymentForm
        payment={payment}
        onLoad={onLoad}
        onFirstLoad={onFirstLoad2}
        {...props}
      />

      {loading && process.env.PAYMENT_SYSTEM === 'old' && (
        <div className={styles.loading}>
          <SpinnerWithText text={t('login.cargando')} />
        </div>
      )}
    </div>
  )
}

export default PaymentForm
