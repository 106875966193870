import React, { FormEvent, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { addCodigoPromocional } from '../../../../context/actions/cestaActions'
import AppContext from '../../../../context/context'
import { intl, t } from '../../../../../common/i18n'
import styles from './index.module.scss'
import { isSpecialErrorMessage } from '../../../../utils/specialErrorMessage'

interface Props {
  price: number
  displayCouponBox: boolean
}
const CestaRowWithCupon = ({ price, displayCouponBox }: Props) => {
  const { cestaState, dispatch } = useContext(AppContext)

  const onCuponSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (event.currentTarget.codigo_promocion.value) {
      dispatch(addCodigoPromocional(event.currentTarget.codigo_promocion.value))
    }
  }

  return (
    <div className={styles.cupon_container}>
      {displayCouponBox ? (
        <div className={styles.cupon}>
          <span className={styles.cupon_text}>
            <FormattedMessage id="cesta.general.descuento" />
          </span>
          <form onSubmit={onCuponSubmit} className={styles.cupon_form}>
            <input
              type="text"
              id="codigo_promocion"
              defaultValue={cestaState.discount.codigo_promocion}
            />
            <button className={styles.button_ok} type="submit">
              OK
            </button>
            {cestaState.cuponError ? (
              <p className={styles.error}>
                {t(isSpecialErrorMessage(cestaState.cuponError.message), {
                  cupon: cestaState.cuponError.cupon,
                })}
              </p>
            ) : null}
          </form>
        </div>
      ) : (
        <div></div>
      )}
      {price && (
        <div className={styles.total_price_container}>
          <span className={styles.total}>Total:</span>
          <span className={styles.total_price}>
            {intl.formatNumber(price, { style: 'currency', currency: 'EUR' })}
          </span>
        </div>
      )}
    </div>
  )
}

export default CestaRowWithCupon
