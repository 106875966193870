import { navigate } from 'gatsby'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import Swal from 'sweetalert2'
import { retrieveDates, unavailableNeumaticos } from '../../../api/cesta'
import * as CestaActions from '../../../context/actions/cestaActions'
import AppContext from '../../../context/context'
import { locale, t } from '../../../i18n'
import logic, { SearchByPostCodeResult } from '../../../logic'
import {
  AvailabilityDate,
  HorarioTallerFront,
  SelectedTaller,
  Taller,
  TallerAvailabilities,
  TallerDistance,
  TallerLocation,
} from '../../../types/Taller'
import { alertProp } from '../../../utils/swal'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import { DataSteps, Steps } from '../Steps'
import TalleresContainer from './TalleresContainer'
import { Product } from '../../../context/reducers/cestaReducer'
import { isAndorraPostalCode } from '../../../utils/getTarifa'
import styles from '../../../pages/area-privada/index.module.scss'
import StandardModal from '../../modal/standardModal/StandardModal'
import InfoModalTaller from '../infoModalTaller/InfoModalTaller'
import {
  getPromocionFechaLimite,
  getSpecialService,
} from '../../../context/selectors/cestaSelectors'

const maxNumTalleres: number =
  parseInt(process.env.GATSBY_MAX_NUM_TALLERES) || 10

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: true,
})

const initialState = {
  loading: false,
  availableSomewhere: true,
  unavailableProducts: [],
  searchedTalleres: [] as (Taller &
    TallerLocation &
    TallerDistance &
    HorarioTallerFront)[],
  availabilities: {} as TallerAvailabilities,
}

type State = typeof initialState
type Action =
  | { type: 'loading' }
  | { type: 'loaded'; talleres: typeof initialState.searchedTalleres }
  | {
      type: 'availability'
      codigo_taller: string
      availability: AvailabilityDate | null
      tyres: Product[]
    }
  | {
      type: 'availability.error'
      codigo_taller: string
      error: Error
    }
  | {
      type: 'availability.none'
      unavailableProducts: Product[]
    }

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'loading':
      return { ...state, loading: true }
    case 'loaded':
      return {
        ...state,
        loading: false,
        searchedTalleres: action.talleres,
        availabilities: action.talleres.reduce((o, t) => {
          o[t.codigo_taller] = { loading: true, availability: null }
          return o
        }, {}),
      }
    case 'availability':
      return {
        ...state,
        availabilities: {
          ...state.availabilities,
          [action.codigo_taller]: {
            loading: false,
            availability: action.availability,
            tyres: action.tyres,
          },
        },
      }
    case 'availability.error':
      return {
        ...state,
        availabilities: {
          ...state.availabilities,
          [action.codigo_taller]: {
            loading: false,
            error: action.error.message,
            availability: null,
          },
        },
      }
    case 'availability.none': {
      return {
        ...state,
        unavailableProducts: action.unavailableProducts,
        availableSomewhere: false,
      }
    }
    default:
      return state
  }
}

export default function CestaTalleres() {
  const { citaPreviaState, cestaState, userInfo, dispatch } =
    useContext(AppContext)
  const [localState, localDispatch] = useReducer(reducer, initialState)
  const [showModalInfo, setShowModalInfo] = useState(false)
  const [tienePortes, setTienePortes] = useState(false)

  const checkAvailability = async (
    talleres: (Taller & TallerLocation & TallerDistance & HorarioTallerFront)[]
  ) => {
    const availables = await Promise.all(
      talleres.map((t, i) =>
        sleep(i * 200)
          .then(async () => {
            return await retrieveDates(
              cestaState.products,
              t.codigo_taller,
              1,
              userInfo
            )
          })
          .then(({ nextDays, neumaticosAdjusted, newSelectedProducts }) => {
            const availability = nextDays.length ? nextDays[0] : null
            localDispatch({
              type: 'availability',
              codigo_taller: t.codigo_taller,
              availability,
              tyres: newSelectedProducts,
            })
            return { availability, neumaticosAdjusted }
          })
          .catch((e) => {
            localDispatch({
              type: 'availability.error',
              codigo_taller: t.codigo_taller,
              error: e,
            })
            return { availability: null, neumaticosAdjusted: null }
          })
      )
    )
    if (!availables.some((availability) => availability.availability)) {
      const unavailableProducts = []
      availables.forEach((availability) => {
        availability.neumaticosAdjusted &&
          availability.neumaticosAdjusted.forEach((neumatico: Product) => {
            const alreadyInArray = unavailableProducts.find(
              (product) => product.id_navision === neumatico.id_navision
            )
            if (!alreadyInArray && neumatico.fechaEntrega.includes('2999')) {
              unavailableProducts.push(neumatico)
            }
          })
      })
      dispatch(
        CestaActions.sendUnavailableNeumaticos(unavailableProducts, userInfo)
      )
      localDispatch({
        type: 'availability.none',
        unavailableProducts,
      })
    }
  }
  const geolocateMe = async () => {
    dispatch(CestaActions.setCodigoPostalIntroducido(''))
    if (!localState.loading) {
      localDispatch({
        type: 'loading',
      })
      navigator.geolocation.getCurrentPosition(async (pos) => {
        const { latitude, longitude } = pos.coords
        logic.filterTiendas('', latitude, longitude).then((res) => {
          localDispatch({
            type: 'loaded',
            talleres: res.slice(0, maxNumTalleres),
          })
          checkAvailability(res)
        })
      })
    }
  }

  const retrieveTalleres = async (postCode: string) => {
    dispatch(CestaActions.setCodigoPostalIntroducido(postCode))

    let talleres: SearchByPostCodeResult
    try {
      localDispatch({ type: 'loading' })
      const specialService = getSpecialService(cestaState)
      talleres = await logic.searchByPostCode(
        postCode,
        true,
        undefined,
        false,
        specialService
      )
      talleres.tiendas = talleres.tiendas.slice(0, maxNumTalleres)
      localDispatch({ type: 'loaded', talleres: talleres.tiendas })
    } catch (err) {
      await Swal.fire(
        alertProp({
          type: 'error',
          title: 'Oops...',
          text: t('cesta.talleres.error_buscar_talleres', { postCode }),
        })
      )
      localDispatch({ type: 'loaded', talleres: [] })
      return
    }

    await checkAvailability(talleres.tiendas)
  }

  const goToFechaHora = () => {
    dispatch(CestaActions.setStep(Steps.FECHA_HORA))
    return navigate(DataSteps[Steps.FECHA_HORA].route)
  }

  function goToNextFromSelectedTaller(taller: SelectedTaller) {
    if (
      isAndorraPostalCode(userInfo.postCode) !==
      isAndorraPostalCode(taller.cpostal)
    ) {
      dispatch(CestaActions.setSelectedTaller(taller))
    } else {
      dispatch(CestaActions.setSelectedTaller(taller))
      return goToFechaHora()
    }
  }

  const onSelectTaller = (taller: SelectedTaller) => {
    const productsWithFechaEntrega =
      localState.availabilities[taller.codigo_taller].tyres
    dispatch(CestaActions.setSelectedTime(productsWithFechaEntrega))
    dispatch(CestaActions.setSelectedTimeOk())
    dispatch(CestaActions.setCodigoPostalIntroducido(taller.cpostal))
    goToNextFromSelectedTaller(taller)
  }

  useEffect(() => {
    if (citaPreviaState.selectedTaller.codigo_taller) {
      goToNextFromSelectedTaller(citaPreviaState.selectedTaller)
    }
  }, [])

  useEffect(() => {
    if (!localState.availableSomewhere) {
      let title = t('cesta.talleres.no_disp_prod_titulo')
      if (unavailableNeumaticos.length > 1) {
        title = t('cesta.talleres.no_disp__prod_titulo_plural')
      }
      title += `: \n${localState.unavailableProducts.map(
        (neumatico) => `${neumatico[`nombre_producto_${locale}`]}\n`
      )}`
      swalWithBootstrapButtons
        .fire({
          title,
          text: t('cesta.talleres.no_disp_texto'),
          type: 'warning',
          confirmButtonText: t('cesta.talleres.no_disp_boton_ok'),
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            return navigate(DataSteps[Steps.TU_SOLICITUD].route)
          }
        })
    }
    const taller = cestaState.selectedTaller
    if (taller.cpostal) {
      if (
        isAndorraPostalCode(userInfo.postCode) !==
        isAndorraPostalCode(taller.cpostal)
      ) {
        setShowModalInfo(true)
      }
    }

    // comprobamos que la cesta contiene portes asignados a algún producto
    setTienePortes(false)
    cestaState.products
      .filter((p) => {
        return p.tipo_producto === 'neumatico'
      })
      .forEach((n) => {
        n.servicios.forEach((s) => {
          if (s.id_navision === 'PORTES_LIG') {
            setTienePortes(true)
          }
        })
      })
  }, [localState.availableSomewhere])

  const fechaLimite = getPromocionFechaLimite(cestaState)
  return (
    <LayoutSelector
      stepActive={cestaState.step}
      customSteps={DataSteps}
      hideSelectorSteps={false}
      title={t('steps.pedir_cita')}
      subTitle={t('steps.escoge_taller')}
      mobileStep={2}
      totalSteps={6}
      showCopyrightFooter>
      <StandardModal
        title={`Taller Rodi ${cestaState.selectedTaller.alias || ''}`}
        showModal={showModalInfo}
        closeModal={() => {
          setShowModalInfo(false)
        }}
        modalClass={styles.modal_small}>
        <InfoModalTaller
          cestaState={cestaState}
          userInfo={userInfo}
          tienePortes={tienePortes}
          goToFechaHora={goToFechaHora}></InfoModalTaller>
      </StandardModal>
      <TalleresContainer
        searchedTalleres={localState.searchedTalleres}
        loading={localState.loading}
        containerState={cestaState}
        availabilities={localState.availabilities}
        nextAction={onSelectTaller}
        retrieveTalleres={retrieveTalleres}
        userInfo={userInfo}
        withDisponibilidad={true}
        geolocateMe={geolocateMe}
        fechaLimite={fechaLimite}
      />
    </LayoutSelector>
  )
}
