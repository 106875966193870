import { useContext } from 'react'
import AppContext from '../../context/context'
import { Steps } from '../../components/cesta/Steps'

function useCestaRedirect(step: Steps) {
  const { cestaState } = useContext(AppContext)

  if (step >= Steps.PAGO) {
    return !cestaState.selectedDate
  }

  if (step >= Steps.OPCIONES && step < Steps.FECHA_HORA) {
    return !cestaState.products.length
  }

  if (step >= Steps.FECHA_HORA) {
    return (
      !cestaState.products.length ||
      !Object.keys(cestaState.selectedTaller).length
    )
  }
  return false
}
export default useCestaRedirect
