import React from 'react'
import { PromotionBanner } from '../../promotionBanner/PromotionBanner'
import { Product, Service } from '../../../context/reducers/cestaReducer'
import Size from '../../../utils/media-queries-setup'
import DropDown from '../../dropDown/DropDown'
import InfoIcon from '../../infoIcon/InfoIcon'
import styles from './index.module.scss'
import CarterClaim from '../../CarterClaim/CarterClaim'
import AireAcondicionadoClaim from '../../AireAcondicionadoClaim/AireAcondicionadoClaim'

interface RowWithoutCheckboxProps {
  item: Service | Partial<Product>
  onChangeQuantity: (event: React.ChangeEvent<HTMLSelectElement>) => void
  nombreProducto: string | null
  masInfoTitulo: string | null
  masInfo: string | null
  descripcionProducto: string | null
  titleClass: string | null
  initialOptions: {
    label: string
    value: string
  }[]
}

const RowWithoutCheckbox: React.FC<RowWithoutCheckboxProps> = ({
  item,
  titleClass,
  nombreProducto,
  masInfoTitulo,
  masInfo,
  descripcionProducto,
  initialOptions,
  onChangeQuantity,
}) => {
  return (
    <div>
      <div className={titleClass || styles.selected_item_title}>
        {nombreProducto}
        {masInfoTitulo || masInfo ? (
          <InfoIcon
            title={masInfoTitulo ? masInfoTitulo.trim() : null}
            text={masInfo ? masInfo.trim() : ''}
          />
        ) : null}
      </div>
      {descripcionProducto && (
        <span className={styles.service_description}>
          {descripcionProducto.trim()}
        </span>
      )}
      <CarterClaim item={item} />
      <AireAcondicionadoClaim item={item} />
      <div className={styles.dropdown_container_mobile}>
        {item.cantidad &&
        ['neumatico', 'camara', 'mousse'].includes(item.tipo_producto) ? (
          <DropDown
            size="small"
            onChange={onChangeQuantity}
            defaultValue={item.cantidad.toString()}
            options={initialOptions}
            labelId={` `}
            labelAbove={true}
          />
        ) : null}
        <Size.Matcher
          desktop={null}
          mobile={
            item.banner && item.banner.visible_cesta ? (
              <div className={styles.promobanner_container}>
                <PromotionBanner promocion={item.banner} />
              </div>
            ) : null
          }
        />
      </div>
    </div>
  )
}

export default RowWithoutCheckbox
