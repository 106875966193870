import * as yup from 'yup'
import { ValidateID } from '../validations/validateDni'
import { t } from '../../i18n'

export const DocumentTypes = ['DNI', 'NIE', 'CIF', 'NIF'] as const
export type DocumentType = typeof DocumentTypes[number]

export const DocumentCountryCodes = ['ES', 'AD', 'PT', 'FR', 'MA'] as const
export type DocumentCountryCode = typeof DocumentCountryCodes[number]

export interface DocumentData {
  documentCountry: DocumentCountryCode
  documentType: DocumentType
  documentValue: string
}

export const DocumentValidations = {
  documentCountry: yup
    .string()
    .oneOf(
      [...DocumentCountryCodes],
      t('validations.register.documentCountry_required')
    ),
  documentType: yup
    .string()
    .oneOf([...DocumentTypes], t('validations.register.documentType_required')),
  documentValue: yup.string().when(['documentType', 'documentCountry'], {
    is: (documentType, documentCountry) =>
      (['DNI', 'NIE', 'CIF'].includes(documentType) &&
        documentCountry === 'ES') ||
      (['NIF'].includes(documentType) && documentCountry === 'PT'),
    then: yup
      .string()
      .test('testDocumentFormat', undefined, function (dni) {
        const { documentType } = this.parent
        return (
          ValidateID(dni, documentType) ||
          this.createError({
            message: t('validations.register.formato_dni', { documentType }),
          })
        )
      })
      .required(
        t('validations.register.required', { label: t('formLabels.document') })
      ),
    otherwise: yup
      .string()
      .max(15, t('validations.register.length_dni'))
      .required(
        t('validations.register.required', { label: t('formLabels.document') })
      ),
  }),
}
export const DocumentSchema = yup.object().shape(DocumentValidations)
