import React from 'react'
import styles from './datosFacturacion.module.scss'
import { IAddressData, IUserData } from '../../../context/reducers/userReducer'
import DocumentInputRow from '../../DocumentInputRow/DocumentInputRow'
import { t } from '../../../i18n'
import { FormMethods } from '../../../types/FormMethods'
import { DatosFacturacionData } from '../../../helpers/schemas/FacturacionSchema'

interface Props {
  onChangeCP: () => void
  requireMatricula: boolean
  userData: IUserData
  addressData: IAddressData
  showForm: boolean
  form: FormMethods<DatosFacturacionData>
}
const DatosFacturacionForm = ({
  onChangeCP,
  requireMatricula,
  userData,
  addressData,
  form,
  showForm,
}: Props) => {
  const { name, surname, dni } = userData
  const { address, block, number, city, province, postCode } = addressData
  const { register, errors } = form

  // Use one form or another depending on how much info we have
  const baseform = (
    <fieldset>
      <div className={styles.data_doc}>
        <label className={styles.section_data_title}>
          {t('mis-datos.nombre')}:
        </label>
        <div className={styles.cont_err}>
          <input
            name="name"
            className={styles.inputForm_med}
            type="text"
            defaultValue={name}
            ref={register}></input>
          <div className={styles.error}>
            {errors.name && errors.name.message}
          </div>
        </div>
      </div>
      <div className={styles.data_doc}>
        <label className={styles.section_data_title}>
          {t('mis-datos.apellidos')}:
        </label>
        <div className={styles.cont_err}>
          <input
            name="surname"
            className={styles.inputForm_med}
            type="text"
            defaultValue={surname || ''}
            ref={register}></input>
          <div className={styles.error}>
            {errors.surname && errors.surname.message}
          </div>
        </div>
      </div>
      <div className={styles.data_doc}>
        <label className={styles.section_data_title}>
          {t('mis-datos.docu')}:
        </label>
        <div className={styles.cont_err}>
          <DocumentInputRow form={form} />
        </div>
      </div>

      <div className={styles.data_doc}>
        <label className={styles.section_data_title}>
          {t('mis-datos.adress')}:
        </label>
        <div className={styles.adress_cont}>
          <label className={styles.adress_text}>{t('mis-datos.calle')}</label>
          <input
            ref={register}
            name="address"
            defaultValue={address}
            className={styles.inputForm_med}
            type="text"></input>
          <div className={styles.error}>
            {errors.address && errors.address.message}
          </div>
        </div>
        <div className={styles.adress_cont}>
          <label className={styles.adress_text}>{t('mis-datos.numero')}</label>
          <input
            ref={register}
            name="number"
            defaultValue={number}
            className={styles.inputForm_small}
            type="text"></input>
          <div className={styles.error}>
            {errors.number && errors.number.message}
          </div>
        </div>
        <div className={styles.adress_cont}>
          <label className={styles.adress_text}>{t('mis-datos.piso')}</label>
          <input
            ref={register}
            name="block"
            defaultValue={block || ''}
            className={styles.inputForm_small}
            type="text"></input>
          <div className={styles.error}>
            {errors.block && errors.block.message}
          </div>
        </div>
      </div>

      <div className={styles.data_doc}>
        <label className={styles.section_data_title}>
          {t('mis-datos.poblacion')}:
        </label>
        <div className={styles.cont_err}>
          <input
            className={styles.inputForm_med}
            name="city"
            defaultValue={city}
            ref={register}
            type="text"
          />
          <div className={styles.error}>
            {errors.city && errors.city.message}
          </div>
        </div>
      </div>

      <div className={styles.data_doc}>
        <label className={styles.section_data_title}>
          {t('mis-datos.codigo-postal')}:
        </label>
        <div className={styles.cont_err}>
          <input
            className={styles.inputForm_small}
            defaultValue={postCode}
            name="postCode"
            ref={register}
            onChange={onChangeCP}
            type="text"
          />
          <div className={styles.error}>
            {errors.postCode && errors.postCode.message}
          </div>
        </div>
      </div>

      <div className={styles.data_doc}>
        <label className={styles.section_data_title}>
          {t('mis-datos.provincia')}:
        </label>
        <div className={styles.cont_err}>
          <input
            name="province"
            defaultValue={province}
            ref={register}
            className={styles.inputForm_med}
            readOnly
          />
          <div className={styles.error}>
            {errors.province && errors.province.message}
          </div>
        </div>
      </div>

      {requireMatricula ? (
        <div className={styles.data_doc}>
          <label className={styles.section_data_title}>
            {t('mis-datos.matricula')}:
          </label>
          <div className={styles.cont_err}>
            <input
              className={styles.inputForm_small}
              name="matricula"
              ref={register}
              type="text"
            />
            <div className={styles.error}>
              {errors.matricula && errors.matricula.message}
            </div>
          </div>
        </div>
      ) : null}
    </fieldset>
  )

  return showForm ? (
    baseform
  ) : (
    <div className={styles.completed_form}>
      <div style={{ display: 'none' }}>{baseform}</div>
      <p>{`${name}${surname ? ` ${surname}` : ''}`.toUpperCase()}</p>
      <p>{dni.toUpperCase()}</p>
      <br />
      <p>{`${address} ${number}${block ? ` ${block}` : ''}`}</p>
      <p>{city}</p>
      <p>{`${postCode} ${province.toUpperCase()}`}</p>
    </div>
  )
}

export default DatosFacturacionForm
