import React from 'react'
import BeatLoader from 'react-spinners/BeatLoader'

interface SpinnerProps {
  loading?: boolean
}

const SpinnerDotted = ({ loading }: SpinnerProps) => {
  return <BeatLoader size={6} color={'#000000'} loading={loading} />
}

export default React.memo(SpinnerDotted)
