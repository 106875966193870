import React from 'react'
import { Product, Service } from '../../../context/reducers/cestaReducer'
import Size from '../../../utils/media-queries-setup'
import CheckBoxYellow from '../../checkboxYellow/CheckBoxYellow'
import InfoIcon from '../../infoIcon/InfoIcon'
import Recomendado from '../../Recomendado/Recomendado'
import styles from './index.module.scss'

interface CestaItemDetails {
  item: Service | Partial<Product>
  handleCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  nombreProducto: string | null
  masInfoTitulo: string | null
  masInfo: string | null
  descripcionProducto: string | null
}

const RowWithCheckbox: React.FC<CestaItemDetails> = ({
  item,
  handleCheckBoxChange,
  nombreProducto,
  masInfoTitulo,
  masInfo,
  descripcionProducto,
}) => {
  return (
    <div className={styles.service_checkbox__name_container}>
      <CheckBoxYellow
        name={item.id_navision}
        onChange={handleCheckBoxChange}
        checked={'selected' in item && item.selected}
        label1={
          <div className={styles.selected_item_title}>
            {nombreProducto}
            {masInfoTitulo && masInfo ? (
              <InfoIcon title={masInfoTitulo.trim()} text={masInfo.trim()} />
            ) : null}
          </div>
        }
        label2={
          <div className={styles.service_description}>
            <Size.Matcher
              mobile={
                'recomendado' in item && item.recomendado === 1 ? (
                  <Recomendado />
                ) : null
              }
              desktop={null}
            />
            {descripcionProducto && descripcionProducto.trim() || null}
          </div>
        }
      />
    </div>
  )
}

export default RowWithCheckbox
