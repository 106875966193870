import { Router } from '@reach/router'
import { graphql, navigate } from 'gatsby'
import React, { useContext, useEffect, useMemo } from 'react'
import CestaReserva from '../../components/cesta/cestaReserva/CestaReserva'
import CestaHorarios from '../../components/cesta/horarios/CestaHorarios'
import Opciones from '../../components/cesta/opciones/Opciones'
import CestaPago from '../../components/cesta/pago/CestaPago'
import CestaRegistro from '../../components/cesta/registro/CestaRegistro'
import { DataSteps, stepFromRoute, Steps } from '../../components/cesta/Steps'
import CestaTalleres from '../../components/cesta/talleres/CestaTalleres'
import TuSolicitud from '../../components/cesta/tuSolicitud/index'
import Layout from '../../components/Layout'
import { SeoData } from '../../types/Seo'
import SpinnerWithText from '../../components/spinnerWithText/SpinnerWithText'
import { setAltaVehiculoStep } from '../../context/actions/altaVehiculoActions'
import * as CestaActions from '../../context/actions/cestaActions'
import { reset } from '../../context/actions/selectorActions'
import AppContext from '../../context/context'
import useCestaRedirect from '../../helpers/customHooks/useCestaRedirect'
import { t } from '../../i18n'
import route from '../../utils/route'
import RedirectToHome from '../../components/RedirectToHome/RedirectToHome'
import { resetCitaPrevia } from '../../context/actions/citaPreviaActions'

interface Props {
  data: {
    seoData: SeoData
  }
}

const Cesta = ({ data }: Props) => {
  const { cestaState, dispatch, altaVehiculoState } = useContext(AppContext)

  const isFirstRender = React.useRef(true)
  useEffect(() => {
    isFirstRender.current = false
  }, [])

  const path =
    typeof document !== 'undefined' ? document.location.pathname : '/'
  const requestedStep = useMemo(() => stepFromRoute(path), [path])
  const shouldRedirect = useCestaRedirect(
    requestedStep ? requestedStep.step : null
  )

  let preventRender = false
  if (shouldRedirect && isFirstRender.current) {
    navigate(route('index'))
    preventRender = true
  }

  const onGoBack = () => {
    dispatch(resetCitaPrevia())
    const step = DataSteps[cestaState.step].prev(cestaState)
    if (step <= 0) {
      dispatch(
        reset({
          keepValues: false,
          origen: route('cesta.solicitud'),
          tipoVehiculo: null,
          temporada: {
            selectable: process.env.GATSBY_WEB === 'rodi',
            value: 'verano',
          },
          marca: null,
        })
      )
      navigate(route('index'))
      return
    }

    dispatch(CestaActions.setStep(step))
    navigate(DataSteps[step].route)
  }

  if (preventRender || typeof window === 'undefined') {
    return null
  }

  if (cestaState.loading) {
    if (altaVehiculoState.step !== 0) {
      dispatch(setAltaVehiculoStep(0))
    }
    return (
      <Layout seoData={null} selector={true}>
        <SpinnerWithText text={t('cesta.general.cargando')} />
      </Layout>
    )
  }

  return (
    <Layout
      currentLocation="cesta"
      seoData={data.seoData}
      onGoBack={onGoBack}
      selector={true}>
      {cestaState.loading ? (
        <SpinnerWithText text={t('cesta.general.cargando')} />
      ) : (
        <Router>
          <TuSolicitud path={DataSteps[Steps.TU_SOLICITUD].route} />
          <Opciones path={DataSteps[Steps.OPCIONES].route} />
          <CestaTalleres path={DataSteps[Steps.TALLERES].route} />
          <CestaHorarios path={DataSteps[Steps.FECHA_HORA].route} />
          <CestaRegistro path={DataSteps[Steps.REGISTRO].route} />
          <CestaPago path={DataSteps[Steps.PAGO].route} />
          <CestaReserva path={DataSteps[Steps.TU_RESERVA].route} />
          <RedirectToHome default />
        </Router>
      )}
    </Layout>
  )
}

export default Cesta

export const pageQuery = graphql`
  query cestaPageQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
