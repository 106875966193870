import React, { FC } from 'react'
import styles from './index.module.scss'
import { DocumentCountryCode } from '../../helpers/schemas/DocumentSchema'

interface countryOption {
  value: DocumentCountryCode
  label: string
}
const countryOptions: countryOption[] = [
  { value: 'ES', label: 'ES' },
  { value: 'AD', label: 'AD' },
  { value: 'PT', label: 'PT' },
  { value: 'FR', label: 'FR' },
  { value: 'MA', label: 'OTHER' },
]

type SelectCountryProps = {
  handleChange: (country: string) => void
  selectedCountry: string
  formRef?: React.LegacyRef<HTMLSelectElement>
  disabled?: boolean
  name: string
}

export const SelectCountry: FC<SelectCountryProps> = ({
  handleChange,
  selectedCountry,
  formRef,
  disabled,
  name,
}) => {
  return (
    <select
      className={styles.selector_dropdown}
      disabled={disabled}
      defaultValue={selectedCountry}
      onChange={(e) => handleChange(e.target.value)}
      name={name}
      ref={formRef}>
      {countryOptions.map((item, index) => {
        return (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        )
      })}
    </select>
  )
}
