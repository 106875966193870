import React, { FC } from 'react'
import styles from './index.module.scss'
import {
  DocumentCountryCode,
  DocumentType,
} from '../../helpers/schemas/DocumentSchema'

interface documentTypeOption {
  value: DocumentType
  label: string
}

const documentTypeOptions: documentTypeOption[] = [
  { label: 'DNI', value: 'DNI' },
  { label: 'NIE', value: 'NIE' },
  { label: 'CIF', value: 'CIF' },
]

const foreignDocumentOptions: documentTypeOption[] = [
  { label: 'NIF', value: 'NIF' },
]

type SelectDocumentTypeProps = {
  handleChange: (documentType: DocumentType) => void
  selectedDocumentType: DocumentType
  selectedCountry: DocumentCountryCode
  formRef?: React.LegacyRef<HTMLSelectElement>
  disabled?: boolean
  name: string
}

export const SelectDocumentType: FC<SelectDocumentTypeProps> = ({
  handleChange,
  selectedDocumentType,
  selectedCountry,
  formRef,
  disabled,
  name,
}) => {
  const options =
    selectedCountry === 'ES' ? documentTypeOptions : foreignDocumentOptions
  return (
    <select
      className={`${styles.selector_dropdown} ${styles.selector_dropdown__document_type}`}
      disabled={disabled}
      defaultValue={selectedDocumentType}
      onChange={(e) => handleChange(e.target.value as DocumentType)}
      name={name}
      ref={formRef}>
      {options.map((item, index) => {
        return (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        )
      })}
    </select>
  )
}
