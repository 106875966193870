import React from 'react'
import styles from './recomendado.module.scss'
import { FormattedMessage } from 'react-intl'

export default function Recomendado() {
  return (
    <span className={styles.recomendado}>
      <FormattedMessage id="recomendado" />
    </span>
  )
}
