import React, { useState } from 'react'
import styles from '../misDatos/index.module.scss'
import { SelectCountry } from './SelectCountry'
import FormError from '../FormError/FormError'
import { SelectDocumentType } from './SelectDocumentType'
import {
  DocumentCountryCode,
  DocumentData,
  DocumentType,
} from '../../helpers/schemas/DocumentSchema'
import { IAppState } from '../../context/context'
import { FormMethods } from '../../types/FormMethods'
import { connect } from 'react-redux'
import { selectUserInfo } from '../../context/selectors/userSelectors'
import { UserState } from '../../context/reducers/userReducer'
import { RegisterDocumentData } from '../../helpers/schemas/RegisterDocumentSchema'

const defaultDocumentCountry =
  process.env.GATSBY_WEB === 'rodimotor' ? 'PT' : 'ES'

const defaultDocumentType =
  process.env.GATSBY_WEB === 'rodimotor' ? 'NIF' : 'DNI'
interface Props {
  form: FormMethods<DocumentData | RegisterDocumentData>
  userInfo: UserState
}

const DocumentInputRow: React.FC<Props> = ({ form, userInfo }) => {
  const {
    documentCountry = null,
    documentType = null,
    dni = null,
  } = userInfo?.userData || {}

  const { register, setValue, errors } = form

  const disabled = userInfo.userData ? !!userInfo.userData.usuario_nav : false

  const [selectedCountry, setSelectedCountry] = useState<DocumentCountryCode>(
    (documentCountry || defaultDocumentCountry) as DocumentCountryCode
  )
  const [selectedDocumentType, setType] = useState<DocumentType>(
    (documentType || defaultDocumentType) as DocumentType
  )

  const handleChangeCountry = (country: DocumentCountryCode) => {
    setSelectedCountry(country)
    const newType = country !== 'ES' ? 'NIF' : 'DNI'
    setType(newType)
    setValue('document.documentType', newType)
  }
  const handleChangeType = (type: DocumentType) => {
    setType(type)
  }
  const handleDniChange = (str: string) => {
    const value = str.replace(/\s/g, '')
    setValue('document.documentValue', value)
  }

  return (
    <div className={styles.document_wrapper}>
      <div className={styles.cont_inputs}>
        <SelectCountry
          disabled={disabled}
          formRef={register}
          handleChange={handleChangeCountry}
          selectedCountry={selectedCountry}
          name="document.documentCountry"
        />

        <SelectDocumentType
          disabled={disabled}
          formRef={register}
          handleChange={handleChangeType}
          selectedCountry={selectedCountry}
          selectedDocumentType={selectedDocumentType}
          name="document.documentType"
        />

        <input
          ref={register}
          disabled={disabled}
          name="document.documentValue"
          defaultValue={dni || ''}
          className={styles.inputForm_small}
          type="text"
          placeholder={selectedDocumentType}
          onChange={(e) => handleDniChange(e.target.value)}></input>
      </div>
      <FormError errors={errors} field="document.documentType" />
      <FormError errors={errors} field="document.documentValue" />
    </div>
  )
}

const mapStateToProps = (state: IAppState) => {
  return {
    userInfo: selectUserInfo(state),
  }
}
export default connect(mapStateToProps)(DocumentInputRow)
