import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Product, Service } from '../../../context/reducers/cestaReducer'
import { intl, t } from '../../../i18n'
import styles from './index.module.scss'

interface PriceProps {
  item: Partial<Product> | Service
  withCheckBox: boolean
  discountServicio?: number
}
const Price: React.FC<PriceProps> = ({
  item,
  withCheckBox: isSelectable,
  discountServicio,
}) => {
  const isSelected = 'selected' in item && item.selected === true
  const importeOriginal =
    'importeSinPromo' in item ? item.importeSinPromo : item.importe
  const importeEfectivo =
    item.importe - (item.importe * (discountServicio || 0)) / 100
  const isActuallyFree = Math.round(importeEfectivo * 100) === 0
  const isDiscounted =
    Math.round(importeEfectivo * 100) !== Math.round(importeOriginal * 100)

  return (
    <div className={styles.discounted_service_price_container}>
      {isDiscounted && (
        <del className={styles.item_price_crossed}>
          <React.Fragment>
            {!isSelected && isSelectable && '+'}
            {intl.formatNumber(importeOriginal, {
              style: 'currency',
              currency: 'EUR',
            })}
          </React.Fragment>
        </del>
      )}

      <span
        className={`${
          !isSelectable || (isSelectable && isSelected)
            ? styles.item_price_checked
            : styles.item_price
        }`}>
        {isActuallyFree ? (
          t('tu_solicitud.gratis')
        ) : (
          <React.Fragment>
            {!isSelected && isSelectable && '+'}
            {intl.formatNumber(importeEfectivo, {
              style: 'currency',
              currency: 'EUR',
            })}
          </React.Fragment>
        )}
      </span>
    </div>
  )
}

export default Price
