import { apiCall } from './api'

interface UserData {
  name: string
  surname: string
  dni: string
  address: string
  poblacion: string
  provincia: string
  postCode: string
  block: string
  number: string
  matricula?: string
}

export function processDatosFacturacion(
  nif: string,
  documentType: string,
  documentCountry: string,
  orderId: string,
  userData: UserData
) {
  return apiCall(`${process.env.GATSBY_API_URL}/pedidos/datos-facturacion`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify({
      numero_documento: nif,
      pais_documento: documentCountry || 'ES',
      tipo_documento: documentType || 'DNI',
      orderId,
      userData,
    }),
  })
}
