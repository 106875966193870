import React, { useCallback, useContext, useEffect } from 'react'
import { restore } from '../../../context/actions/selectorActions'
import AppContext from '../../../context/context'
import StandardModal from '../../modal/standardModal/StandardModal'
import SelectorContainer from './SelectorContainer'
import SelectorForm from './SelectorForm'
import styles from './selectorModal.module.scss'

interface Props {
  showMarcas?: boolean
  closeModal: () => void
  showModal: boolean
  title: string
  onSubmit: () => void
}

let storedState = null

const SelectorModal = ({
  showMarcas,
  closeModal,
  showModal,
  title,
  onSubmit,
}: Props) => {
  const { selectorState, dispatch } = useContext(AppContext)

  useEffect(() => {
    if (showModal) {
      storedState = selectorState
    }
  }, [showModal])

  const onCloseModal = useCallback(() => {
    dispatch(restore(storedState))
    storedState = null
    closeModal()
  }, [closeModal])

  const handleSubmit = () => {
    closeModal()
    onSubmit()
  }

  return (
    <StandardModal
      closeModal={onCloseModal}
      showModal={showModal}
      title={title}
      id="selector-modal">
      <section className={styles.sections}>
        <div className={styles.tabs_home_wrapper}>
          <SelectorContainer showMarcas={showMarcas}>
            {({ ...props }) => (
              <SelectorForm
                handleSubmit={handleSubmit}
                fromModal={true}
                {...props}
              />
            )}
          </SelectorContainer>
        </div>
      </section>
    </StandardModal>
  )
}

export default SelectorModal
