import { navigate } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import * as api from '../../../api/cesta'
import { IDate, ITime } from '../../../api/citaPrevia'
import Next from '../../../assets/images/Talleres/next.svg'
import * as CestaActions from '../../../context/actions/cestaActions'
import AppContext from '../../../context/context'
import { t } from '../../../i18n'
import Size from '../../../utils/media-queries-setup'
import { alertProp } from '../../../utils/swal'
import Button from '../../buttons/Button'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import CestaLastRow from '../cestaItem/cestaLastRow/CestaLastRow'
import { DataSteps, Steps } from '../Steps'
import HorariosContainer from './HorariosContainer'
import styles from './index.module.scss'
import { CestaOrigin } from '../../../context/reducers/cestaReducer'
import { resetCitaPrevia } from '../../../context/actions/citaPreviaActions'
import { AvailabilityDate } from '../../../types/Taller'
import moment from 'moment'

const findSelectedDate = (possible: IDate[], selected: ITime | null) => {
  if (!selected) return possible[0]

  const ymdDate = selected.date.split('-').reverse().join('-')
  return possible.find((d) => d.originalDate === ymdDate) || possible[0]
}
const filterDatesWithAvailabilityTallerDate = (
  dates: IDate[],
  availabilityDate: AvailabilityDate
) => {
  console.log(availabilityDate)
  const originalDate = availabilityDate.originalDate
  console.log(originalDate)
  return dates.filter(
    (date) => new Date(date.originalDate) >= new Date(originalDate)
  )
}
const filterTimesWithAvailabilityTallerDate = (
  times: ITime[],
  availabilityDate: AvailabilityDate
) => {
  const originalDate = availabilityDate.originalDate

  return times.filter((time) => moment(time.moment) >= moment(originalDate))
}
export default function CestaHorarios() {
  const { cestaState, userInfo, dispatch } = useContext(AppContext)
  const [dates, setDates] = useState(null)
  const [times, setTimes] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null as IDate | null)
  const [datesLoading, setDatesLoading] = useState(false)
  const [timesLoading, setTimesLoading] = useState(false)
  const [selectedTime, setSelectedTime] = useState(null as ITime | null)
  const [selectedProducts, setSelectedProducts] = useState(null)

  const getDates = async () => {
    const { products, selectedTaller } = cestaState

    // get dates
    setDatesLoading(true)
    setTimesLoading(true)
    let fetchedDates: IDate[] = [] as IDate[]
    let selectedDate = null as IDate | null
    try {
      const response = await api.retrieveDates(
        products,
        selectedTaller.codigo_taller,
        undefined,
        userInfo
      )

      const { nextDays, newSelectedProducts } = response
      fetchedDates = nextDays as IDate[]
      if (selectedTaller.availabilityDate) {
        fetchedDates = filterDatesWithAvailabilityTallerDate(
          nextDays as IDate[],
          selectedTaller.availabilityDate
        ) as IDate[]
      }

      selectedDate = findSelectedDate(fetchedDates, null)
      setSelectedDate(selectedDate)
      setSelectedProducts(newSelectedProducts)
    } catch (err) {
      setDatesLoading(false)
      setTimesLoading(false)
      Swal.fire(
        alertProp({
          type: 'error',
          title: 'Oops...',
          text: t('horarios-page.problema_horarios'),
        })
      )
      return
    }
    setDatesLoading(false)
    setDates(fetchedDates)
    // get times
    let fetchedTimes
    try {
      fetchedTimes = await api.retrieveTimes(
        selectedTaller.codigo_taller,
        selectedDate.originalDate,
        cestaState.products,
        userInfo.email
      )
    } catch (err) {
      setTimesLoading(false)
      return Swal.fire(
        alertProp({
          type: 'error',
          title: 'Oops...',
          text: t('horarios-page.problema_horarios'),
        })
      )
    }

    setTimesLoading(false)
    setTimes(fetchedTimes)
  }

  useEffect(() => {
    dispatch(CestaActions.setSelectedDate(null))
    getDates()
  }, [])

  const onBackClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    dispatch(resetCitaPrevia())

    const prevStep =
      cestaState.origin === CestaOrigin.External
        ? Steps.OPCIONES
        : Steps.TALLERES
    // move to next step
    dispatch(CestaActions.setStep(prevStep))
    return navigate(DataSteps[prevStep].route)
  }

  const onSelectDate = async (item: IDate) => {
    const { selectedTaller } = cestaState
    setSelectedDate(item)
    setTimesLoading(true)
    const fetchedTimes = await api.retrieveTimes(
      selectedTaller.codigo_taller,
      item.originalDate,
      cestaState.products,
      userInfo.email
    )
    setTimesLoading(false)
    setTimes(fetchedTimes)
  }

  const onSelectTime = (item: ITime) => {
    dispatch(CestaActions.setSelectedDate(item))
    setSelectedTime(item)
  }

  const onComprarClick = async () => {
    if (!cestaState.selectedDate) {
      return Swal.fire(
        alertProp({
          type: 'error',
          title: 'Oops...',
          text: t('horarios-page.problema_horarios'),
        })
      )
    }
    try {
      dispatch(
        CestaActions.setTimeCesta(
          cestaState.selectedDate,
          cestaState.selectedTaller.codigo_taller,
          userInfo.email,
          selectedProducts
        )
      )
      dispatch(CestaActions.updateProducts(selectedProducts))
      if (userInfo.idUser !== null) {
        dispatch(CestaActions.setStep(Steps.PAGO))
        navigate(DataSteps[Steps.PAGO].route)
      } else {
        dispatch(CestaActions.setStep(Steps.REGISTRO))
        navigate(DataSteps[Steps.REGISTRO].route)
      }
    } catch (err) {
      alert('Ha habido un error')
    }
  }

  return (
    <LayoutSelector
      stepActive={cestaState.step}
      customSteps={DataSteps}
      hideSelectorSteps={false}
      title={t('steps.pedir_cita')}
      subTitle={t('steps.fecha_hora')}
      mobileStep={3}
      totalSteps={6}
      showCopyrightFooter>
      <div className={styles.horarios_container}>
        <div className={styles.horarios_container_left}>
          <h4>{t('fecha_hora.fecha_visita')}</h4>
          <HorariosContainer
            selectedCodigoTaller={cestaState.selectedTaller.codigo_taller}
            selectedCodigoPostal={cestaState.selectedTaller.cpostal}
            datesLoading={datesLoading}
            dates={dates}
            onSelectDate={onSelectDate}
            selectedDate={selectedDate}
            timesLoading={timesLoading}
            times={times}
            onSelectTime={onSelectTime}
            selectedTime={selectedTime}
            origen="pedido"
          />
          <Size.Matcher
            mobile={
              <div className={styles.button_cont}>
                <Button buttonClass="next_button" onClick={onComprarClick}>
                  {t('selector-desktop.continuar')}
                  <Next />
                </Button>
              </div>
            }
            desktop={
              <CestaLastRow
                onContinuarClick={onComprarClick}
                onBackClick={onBackClick}
              />
            }
          />
        </div>
        <Size.Matcher
          mobile={null}
          desktop={
            <FunnelSideBar currentLocation="cesta" cestaState={cestaState} />
          }
        />
      </div>
    </LayoutSelector>
  )
}
