import React, { useContext } from 'react'
import AppContext from '../../../context/context'
import * as CestaActions from '../../../context/actions/cestaActions'
import CestaRadioButtonItem from '../cestaItem/CestaRadioButtonItem'

const BateriaOpciones: React.FC = () => {
  const { cestaState, dispatch } = useContext(AppContext)
  const handleRadioChange = (selectedIdNavision: string) => {
    dispatch(CestaActions.switchService(selectedIdNavision))
  }

  return (
    <React.Fragment>
      {cestaState.services
        .sort((a, b) => (a.orden >= b.orden ? 1 : -1))
        .map((service) => (
          <CestaRadioButtonItem
            item={service}
            handleChange={handleRadioChange}
            key={`service_${service.id_navision}`}
            products={cestaState.products.filter(
              (product) => product.tipo_producto === 'bateria'
            )}
            hasServiceDiscount={false}
          />
        ))}
    </React.Fragment>
  )
}

export default BateriaOpciones
