import * as yup from 'yup'
import { ValidateCP } from '../../../specific/helpers/validation/validationCP'
import { matricula } from '../../constants/matricula'
import { t } from '../../i18n'
import { DocumentData, DocumentSchema } from './DocumentSchema'

export interface DatosFacturacionData {
  document: DocumentData
  name: string
  surname: string
  address: string
  number: string
  block: string
  city: string
  postCode: string
  province: string
  matricula?: string
  pago: string
}

export const FacturacionSchema = (withMatricula = false) => {
  const base = {
    document: DocumentSchema,
    name: yup
      .string()
      .min(3, t('validations.form_facturacion.name.short'))
      .required(t('validations.form_facturacion.name.required')),
    surname: yup
      .string()
      .required(t('validations.form_facturacion.surname.required')),
    address: yup
      .string()
      .min(5, t('validations.form_facturacion.address.too_short'))
      .required(t('validations.form_facturacion.address.required')),
    number: yup
      .string()
      .matches(/[0-9]/, t('validations.form_facturacion.number.required'))
      .required(t('validations.form_facturacion.number.required')),
    block: yup.string(),
    city: yup
      .string()
      .required(t('validations.form_facturacion.city.required')),
    postCode: yup
      .string()
      .test(
        'test-codigopostal',
        t('validations.form_facturacion.postalCode.invalid'),
        (cp) => ValidateCP(cp)
      )
      .required(t('validations.form_facturacion.postalCode.required')),
    province: yup
      .string()
      .required(t('validations.form_facturacion.province.required')),
  }

  const shape = withMatricula ? { ...base, ...matricula } : base
  return yup.object().shape(shape)
}
