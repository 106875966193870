import { navigate } from 'gatsby'
import React, { useContext } from 'react'
import AppContext from '../../../context/context'
import { t } from '../../../../common/i18n'
import Size from '../../../utils/media-queries-setup'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import * as CestaActions from '../../../context/actions/cestaActions'
import RegisterContainer from '../../registerForm/RegisterContainer'
import { DataSteps, Steps } from '../Steps'
import styles from './index.module.scss'

export interface CestaPageProps {
  path: string
}
const CestaRegistro: React.FC<CestaPageProps> = () => {
  const { cestaState, dispatch } = useContext(AppContext)
  const onRegisterSuccess = () => {
    dispatch(CestaActions.setStep(Steps.PAGO))
    navigate(DataSteps[Steps.PAGO].route)
  }

  const onGoBack = () => {
    dispatch(CestaActions.setStep(Steps.FECHA_HORA))
    navigate(DataSteps[Steps.FECHA_HORA].route)
  }

  return (
    <LayoutSelector
      stepActive={cestaState.step}
      customSteps={DataSteps}
      hideSelectorSteps={false}
      title={t('steps.pedir_cita')}
      subTitle={t('steps_cesta.registro')}
      mobileStep={4}
      totalSteps={6}
      showCopyrightFooter>
      <div className={styles.registro_container_left}>
        <RegisterContainer
          showLoginBar="register"
          onGoBack={onGoBack}
          onRegisterSuccess={onRegisterSuccess}
          onLoginSuccess={onRegisterSuccess}
        />
      </div>
      <Size.Matcher
        mobile={null}
        desktop={
          <FunnelSideBar currentLocation="cesta" cestaState={cestaState} />
        }
      />
    </LayoutSelector>
  )
}

export default CestaRegistro
